<template>
  <div class="titleBox">
    <div class="title1" v-if="$i18n.locale == 'zh'">
      <b></b>
      <span>{{ titleChina }}</span>
      <b></b>
    </div>
    <div class="title2">{{ titleEng }}</div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: ["titleChina", "titleEng"]
};
</script>

<style lang="scss">
.titleBox {
  margin-top: 70px;
  text-align: center;
  .title1 {
    display: flex;
    align-items: center;
    justify-content: center;
    b {
      display: inline-block;
      width: 20px;
      height: 3px;
      background: rgba(5, 100, 205, 1);
    }
    span {
      margin: 0 9px;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 28px;
      font-size: 20px;
    }
  }
  .title2 {
    margin-top: 3px;
    font-size: 40px;
    font-family: YouSheBiaoTiHei;
    color: rgba(0, 0, 0, 1);
    line-height: 50px;
  }
}
</style>
