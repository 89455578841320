<!--
 * @Editors: wen jing
 -->
<template>
  <div id="container"></div>
</template>

<script>
export default {
  name: "txmap",
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const center = new window.qq.maps.LatLng(39.926566, 116.671154);
      const map = new window.qq.maps.Map(document.getElementById("container"), {
        //地图的中心地理坐标
        center: center,
        zoom: 15,
        scrollwheel: true
      });
      new window.qq.maps.Marker({
        position: center,
        map: map
      });
    }
  }
};
</script>

<style lang="scss">
#container {
  width: 100%;
  height: 700px;
}
</style>
