<!--
 * @Editors: wen jing
 -->
<template>
  <div>
    <Header title="contact"></Header>
    <BannerTitle title="contact"></BannerTitle>
    <div class="contactBox">
      <div class="businessBox">
        <Title
          titleChina="商务合作"
          titleEng="BUSINESS"
          class="wow slideInUp"
        ></Title>
        <div class="wrapBox">
          <img class="topPic" src="@/assets/images/v2/contact.png" alt="" />
          <ul  class="wow slideInUp">
            <li>
              <div>
                <p>{{ $t("contact.business.tit1") }}</p>
                <span>{{ $t("contact.business.owner1") }}</span>
                <span>{{ $t("contact.business.qq1") }}</span>
              </div>
              <img src="@/assets/images/cui.png" alt="" />
            </li>
            <!-- <li>
              <div>
                <p>{{ $t("contact.business.tit2") }}</p>
                <span>{{ $t("contact.business.owner2") }}</span>
                <span>{{ $t("contact.business.qq2") }}</span>
              </div>
              <img src="@/assets/images/liang.png" alt="" />
            </li> -->
          </ul>
        </div>
      </div>
      <div class="contact">
        <Title
          titleChina="联系方式"
          titleEng="CONTACT"
          class="wow slideInUp"
        ></Title>
        <ul  class="wow slideInUp">
          <li>
            <img src="@/assets/images/mail.png" alt="" />
            <span>{{ $t("home.contact.tips1") }}</span>
            <p>{{ $t("home.contact.tips2") }}</p>
          </li>
          <li>
            <img src="@/assets/images/adress.png" alt="" />
            <span>{{ $t("home.contact.tips3") }}</span>
            <p>{{ $t("home.contact.tips4") }}</p>
          </li>
          <li>
            <img src="@/assets/images/number.png" alt="" />
            <span>{{ $t("home.contact.tips5") }}</span>
            <p>{{ $t("home.contact.tips6") }}</p>
          </li>
        </ul>
      </div>
    </div>
    <TXmap></TXmap>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import TXmap from "./map.vue";
import Title from "../../components/title.vue";
export default {
  name: "contact",
  components: {
    Header,
    BannerTitle,
    Footer,
    Title,
    TXmap
  }
};
</script>

<style lang="scss">
.contactBox {
  .businessBox {
    margin-bottom: 142px;

    .wrapBox {
      width: 990px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: space-between;
      margin-top: 80px;

      .topPic {
        width: 493px;
        height: 456px;
      }
    }

    ul {
      // width: 834px;
      // margin: 100px auto 0;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      li {
        width: 354px;
        margin-bottom: 45px;
        div {
          width: 252px;
          // margin-right: 22px;
          float: left;
          p {
            color: #000;
            line-height: 39px;
            // font-family: YouSheBiaoTiHei;
            font-size: 22px;
            margin-bottom: 18px;
            font-weight: 600;
          }
          span {
            font-weight: 400;
            display: inline-block;
            color: rgba(34, 34, 34, 1);
            margin-bottom: 8px;
            line-height: 22px;
          }
        }
        img {
          width: 102px;
          float: right;
          margin-top: 4px;
        }
      }
    }
  }
  .contact {
    padding: 47px 0 107px 0;
    background: rgba(250, 250, 250, 1);
    ul {
      width: 1300px;
      margin: 100px auto 0;
      display: flex;
      justify-content: space-between;
      li {
        width: 397px;
        height: 197px;
        background: rgba(255, 255, 255, 1);
        padding: 92px 0 85px 0;
        text-align: center;
        img {
          width: 80px;
          height: 70px;
          display: block;
          margin: auto;
        }
        span {
          margin: 29px 0 12px 0;
          font-weight: 600;
          color: rgba(153, 153, 153, 1);
          line-height: 22px;
          display: block;
        }
        p {
          width: 278px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(34, 34, 34, 1);
          line-height: 34px;
          margin: auto;
        }
      }
    }
  }
}
</style>
